<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-text>
          <v-card-title>
            Lista de resoluciones de facturación
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="numerations"
            :search="search"
            class="elevation-1"
          />
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ShowNumerations",
  data() {
    return {
      show: false,
      search: "",
      alertStatus: true,
      progress: false,
      headers: [
        { text: "Id", value: "IdCompania" },
        { text: "Nombre Compañia", value: "NombreCompania" },
        { text: "Prefijo", value: "Prefijo" },
        { text: "Número de Resolución", value: "NumeroResolucion" },
        { text: "Fecha de Resolución", value: "FechaResolucion" },
        { text: "Activo", value: "Activo" },
        { text: "Número Desde", value: "NumeroDesde" },
        { text: "Número Hasta", value: "NumeroHasta" },
        { text: "Número Inicial", value: "NumeroInicial" },
        { text: "Clave Técnica", value: "ClaveTecnica" },
        { text: "Fecha Desde", value: "FechaDesde" },
        { text: "Fecha Hasta", value: "FechaHasta" },
      ],
    };
  },
  computed: {
    numerations() {
      return this.$store.state.numerations.listNumerations.Mensaje;
    },
  },
  methods: {
    clearTable() {
      this.$store.commit("resetListNumerations");
    },
    showAlert(message) {
      alert(message);
      this.clearTable();
    },
  },
};
</script>